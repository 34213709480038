import { Card, Table } from "antd";
import CardRotacion from "components/molecules/CardRotacion";
import InformacionResidente from "components/molecules/InformacionResidente";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import { useDataActualizadaResidente } from "hooks/useDataActualizadaResidente";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Heading } from "util-styled-components";

const MisRotaciones = () => {
  let { id } = useParams();
  const { data } = useDataActualizadaResidente(id);

  const Rotaciones = useAxios("POST", "/Rotaciones-x-residente", {}, false);

  const getRotaciones = () => {
    Rotaciones.updateParams({
      IdResidente: id,
    });
    Rotaciones.initRequest();
  };

  useEffect(() => {
    getRotaciones();

    return () => {};
  }, [id]);

  return (
    <Container>
      <InformacionResidente data={data} />
      <Heading variant="h2">Mis rotaciones</Heading>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Table
          dataSource={Rotaciones.data}
          columns={[
            {
              className: "no-padding",
              render: (_, rotacion) => (
                <CardRotacion data={rotacion} noBorder />
              ),
            },
          ]}
        />
        {/* {Array.isArray(Rotaciones.data) &&
          Rotaciones.data.map((rotacion) => )} */}
      </div>
    </Container>
  );
};

export default MisRotaciones;
