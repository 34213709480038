export const generateMapTable = (jsonArray) => {
  if (!Array.isArray(jsonArray)) {
    return [];
  }
  return jsonArray.map((el) => tableColumn(el));
};

//If add a json object to the array, "dataIndex" is required
const tableColumn = (column) => {
  if (typeof column === "object") {
    return {
      ...column,
      key: column.key ? column.key : column.dataIndex,
      title: column.title ? column.title : column.dataIndex,
      dataIndex: column.dataIndex ? column.dataIndex : column.key,
    };
  }

  return {
    title: column,
    key: column,
    dataIndex: column,
  };
};
