import XLSX from "xlsx";
import { saveAs } from "file-saver";

export default function SaveAsExcel(data, title) {
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: { title },
    Subject: "Test file",
    Author: "Frank Nolasco",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push(title);
  var ws_data = data;
  var ws = XLSX.utils.json_to_sheet(ws_data);
  wb.Sheets[title] = ws;
  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = (s) => {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let index = 0; index < s.length; index++) {
      view[index] = s.charCodeAt(index) & 0xff;
    }
    return buf;
  };
  saveAs(new Blob([s2ab(wbout)]), title + ".xlsx");
}
