import { Descriptions, message } from "antd";
import DragDrop from "components/molecules/DragDrop";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formulario } from "ts_formulario_model";
import { Div, Heading, Typography } from "util-styled-components";
import { CargarNotaMap } from "../MapFormulario";
import uf from "util-functions/src/core/strings";
import InformacionResidente from "components/molecules/InformacionResidente";
import { useDataActualizadaResidente } from "hooks/useDataActualizadaResidente";

const SubirNotasResidentes = () => {
  const [fileUploaded, setFileUploaded] = useState(null);
  const { id, idRotacion } = useParams();
  const { state } = useLocation();
  const nav = useNavigate();
  const da = useDataActualizadaResidente(id);

  const ActualizarNotas = useAxios("POST", "/Actualizar-notas", {}, false);

  useEffect(() => {
    if (ActualizarNotas.responseCode) {
      ActualizarNotas.responseCode === 200 &&
        message.success("Se actualizó la nota");
      ActualizarNotas.responseCode === 400 &&
        message.error("No se actualizó la nota");
      nav(`/administrador/residentes/rotaciones/${id}`);
    }

    return () => {};
  }, [ActualizarNotas.responseCode]);

  const getURLForFIleUploaded = () => {
    try {
      const url = fileUploaded.response.url;
      if (uf.isUrl(url)) {
        return url;
      }
      return "";
    } catch (error) {
      throw error;
    }
  };

  const guardarNotas = (data) => {
    try {
      const params = {
        ...data,
        IdRotacion: idRotacion,
        NOTA_URL: fileUploaded ? getURLForFIleUploaded() : "",
      };
      ActualizarNotas.updateParams(params);
      ActualizarNotas.initRequest();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div width="92%" margin="auto">
      {da.data && (
        <Formulario onSubmit={guardarNotas}>
          <Formulario.Title>Subir notas de Recidente</Formulario.Title>
          <InformacionResidente data={da.data} />
          <Formulario.FormControls
            onCancel={() => {
              nav(`/administrador/residentes/rotaciones/${id}`);
            }}
            inputs={[
              {
                name: "NotaFinal",
                type: "input",
                required: true,
                defaultValue: state ? state.NotaFinal : "",
                customProps: {
                  required: true,
                },
              },
              {
                name: "Observaciones",
                type: "input",
                defaultValue: state
                  ? state.Observaciones
                    ? state.Observaciones
                    : ""
                  : "",
              },
            ]}
          >
            <Div width={"100%"} margin="20px auto">
              <Typography variant="p">Subir archivo de notas</Typography>
              <DragDrop uploadedFile={(file) => setFileUploaded(file)} />
            </Div>
          </Formulario.FormControls>
        </Formulario>
      )}
    </Div>
  );
};

export default SubirNotasResidentes;
