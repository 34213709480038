import { Button, Checkbox, Input, message, Select, Table } from "antd";
import { Container } from "components/skeleton/Container";
import { useLoading } from "hooks/LoadingContext";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { Column, Row } from "util-styled-components";
import { generateMapTable } from "utils/tables";
import { fetchPost } from "utils/simpleAxios";
import { useNavigate } from "react-router-dom";
import { useUsuario } from "hooks/ContextUser";
const { Option } = Select;
const buscador = "/Establecimientos/buscar";
const cargar = "/Cargar-Establecimientos";

const ActivarCentroSalud = () => {
  const { tokenInterceptor } = useUsuario();
  const [select, setSelect] = useState("minsa");
  const [activeButton, setActiveButton] = useState(false);
  const CentrosNM = useAxios("POST", `${buscador}/no-minsa`, {}, false);
  const CentrosMinsa = useAxios("POST", `${buscador}/minsa`, {}, false);
  const nav = useNavigate();
  const [listaActivar, setListaActivar] = useState([]);

  const onSearch = async (keyword) => {
    const params = { keyword };
    if (select === "minsa") {
      CentrosMinsa.updateParams(params);
      CentrosMinsa.initRequest();
    } else {
      CentrosNM.updateParams(params);
      CentrosNM.initRequest();
    }
  };

  const activeButtonSelector = (active, code) => {
    if (active) {
      setListaActivar([...listaActivar, code]);
    } else {
      setListaActivar(listaActivar.filter((el) => el !== code));
    }
  };

  useEffect(() => {
    setActiveButton(listaActivar.length > 0);
    return () => {};
  }, [listaActivar]);

  const guardarSeleccion = () => {
    listaActivar.forEach(async (el) => {
      const params = {
        id: el,
        type: select.toUpperCase(),
      };
      await fetchPost(cargar, params, tokenInterceptor);
    });
    setListaActivar([]);
    CentrosMinsa.setData([]);
    CentrosNM.setData([]);
    message.success("Se ha guardado la selección");
    nav("/administrador/centros-de-salud");
  };

  return (
    <Container>
      <Column gap="20px">
        <Row gap="10px">
          {/* <Select
            style={{ minWidth: 120, maxWidth: 120, display: "inline" }}
            size="large"
            value={select}
            dropdownStyle={{ width: "100%" }}
            onChange={(value) => {
              setSelect(value);
            }}
            disabled={activeButton}
          >
            <Option value="minsa">Minsa</Option>
            <Option value="no-minsa">No minsa</Option>
          </Select> */}
          <Input.Search
            style={{ display: "inline" }}
            size="large"
            placeholder="Buscar centros de salud"
            enterButton={activeButton ? undefined : "Buscar"}
            onSearch={onSearch}
          />
          {activeButton && (
            <Button size="large" type="primary" onClick={guardarSeleccion}>
              Guardar
            </Button>
          )}
        </Row>
        <Table
          loading={CentrosNM.loading || CentrosMinsa.loading}
          columns={generateMapTable([
            select === "minsa" ? "Codigo" : "codigo",
            "Nombre",
            {
              dataIndex: "IdTipoEstablecimiento",
              title: "Tipo",
              render: () => select.toLocaleUpperCase(),
            },
            {
              dataIndex: "activar",
              title: "Activar",
              render: (_, render) => (
                <Checkbox
                  style={{ textAlign: "center" }}
                  onChange={({ target }) =>
                    activeButtonSelector(
                      target.checked,
                      select === "minsa"
                        ? render.IdEstablecimiento
                        : render.IdEstablecimientoNoMinsa
                    )
                  }
                />
              ),
            },
          ])}
          dataSource={select === "minsa" ? CentrosMinsa.data : CentrosNM.data}
        />
      </Column>
    </Container>
  );
};

export default ActivarCentroSalud;
