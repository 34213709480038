import React, { useState, useMemo } from "react";

const LoadContext = React.createContext();

export function LoadingProvider(props) {
  const [loading, setLoading] = useState(false);
  const [activeModalLoading, setActiveModalLoading] = useState(false);

  const changeLoading = (value = true, activeModal = true) => {
    setLoading(value);
    setActiveModalLoading(value ? activeModal : false);
  };

  const value = useMemo(() => {
    return {
      loading,
      activeModalLoading,
      changeLoading,
    };
    //eslint-disable-next-line
  }, [loading, activeModalLoading]);
  return <LoadContext.Provider value={value} {...props} />;
}
export function useLoading() {
  const context = React.useContext(LoadContext);
  if (!context) {
    throw new Error("No se encontro Usuario en el contexto");
  }
  return context;
}
