import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "antd";
import InformacionResidente from "components/molecules/InformacionResidente";
import { Container } from "components/skeleton/Container";
import { format } from "date-fns";
import useAxios from "hooks/useAxios";
import { useDataActualizadaResidente } from "hooks/useDataActualizadaResidente";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Heading, Row } from "util-styled-components";
import { generateMapTable } from "utils/tables";
import uf from "util-functions/src";
const residentesURI = "/administrador/residentes";

const VerRotacionesResidentes = () => {
  const { id } = useParams();
  const Rotaciones = useAxios("POST", "/Rotaciones-x-residente", {}, false);
  const nav = useNavigate();
  const da = useDataActualizadaResidente(id);

  const getRotaciones = () => {
    Rotaciones.updateParams({
      IdResidente: id,
    });
    Rotaciones.initRequest();
  };

  useEffect(() => {
    getRotaciones();

    return () => {};
  }, [id]);

  return (
    da.data && (
      <Container>
        <Heading variant="h2">Rotaciones</Heading>
        <InformacionResidente
          data={da.data}
          extraInfo={da.data.NombreEspecialidad}
          extra={
            <Button
              type="primary"
              onClick={() => nav(`${residentesURI}/rotaciones/${id}/crear`)}
            >
              <Row gap="5px">
                <FontAwesomeIcon icon={faRotate} /> Rotar
              </Row>
            </Button>
          }
        />
        <Table
          style={{ overflowX: "auto" }}
          columns={generateMapTable([
            "Nombre",
            {
              dataIndex: "CampoFormacion",
              title: "Campo de formación",
            },
            "Periodo",
            {
              dataIndex: "FechaInicio",
              render: (_, row) =>
                uf.formatLocalTZ(row.FechaInicio, "dd/MM/yyyy"),
            },
            {
              dataIndex: "FechaFin",
              render: (_, row) =>
                row.FechaFin
                  ? uf.formatLocalTZ(row.FechaFin, "dd/MM/yyyy")
                  : "-------",
            },
            "NotaFinal",
            "Observaciones",
            {
              dataIndex: "Archivo notas",
              render: (_, row) => (
                <Row>
                  {row.Periodo === "Vacaciones" ? (
                    "----"
                  ) : row.URL_NOTA ? (
                    <a href={row.URL_NOTA} target="_blank">
                      <Button>Ir al archivo</Button>
                    </a>
                  ) : (
                    <Button
                      size="small"
                      type="link"
                      onClick={() => {
                        nav(
                          `${residentesURI}/notas/${id}/crear/${row.IdRotacion}`,
                          {
                            state: row,
                          }
                        );
                      }}
                    >
                      Cargar notas
                    </Button>
                  )}
                </Row>
              ),
            },
            {
              dataIndex: "Acciones",
              render: (_, row) => (
                <Row>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      nav(
                        `${residentesURI}/rotaciones/${id}/editar/${row.IdRotacion}`,
                        {
                          state: row,
                        }
                      );
                    }}
                  >
                    Editar
                  </Button>
                </Row>
              ),
            },
          ])}
          dataSource={Rotaciones.data}
        />
      </Container>
    )
  );
};

export default VerRotacionesResidentes;
