import { Button, Card, Table } from "antd";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React, { useRef, useState } from "react";
import { Formulario } from "ts_formulario_model";
import { Heading, Row } from "util-styled-components";
import momentTZ from "utils/momentTZ";
import { generateMapTable } from "utils/tables";
import uf from "util-functions/src";
import SaveAsExcel from "Exports/Excel";
const RotacionesXMes = () => {
  const ref = useRef(null);

  const [Selecccionado, setSelecccionado] = useState({});
  const ReporteEvaluacionesAPI = useAxios(
    "POST",
    "/ReporteRotacionesXMes",
    {},
    false
  );

  const Buscar = ({ Periodo }) => {
    try {
      const detr = Periodo.toString().split("-");
      if (detr.length >= 2) {
        const [año, mes] = detr;
        ReporteEvaluacionesAPI.updateParams({ año, mes });
        ReporteEvaluacionesAPI.initRequest();
      }
    } catch (error) {}
  };

  return (
    <Container>
      <Card style={{ marginBottom: 15 }}>
        <Heading variant="h3">Reporte de rotaciones por mes</Heading>
        <Formulario onSubmit={Buscar}>
          <Formulario.FormControls
            inputs={[
              {
                name: "Periodo",
                type: "input",
                required: true,
                customProps: {
                  type: "month",
                  required: true,
                },
              },
            ]}
            submitLabel="Buscar"
            cancelLabel="<>"
          />
        </Formulario>
      </Card>
      <Card>
        <Row justifyContent="space-between" margin="0 0 15px 0">
          <Heading>Rotaciones</Heading>
          {ReporteEvaluacionesAPI.data.length > 0 && (
            <Row justifyContent="flex-end" gap="30px">
              <Button
                type="link"
                onClick={() => {
                  SaveAsExcel(
                    ReporteEvaluacionesAPI.data,
                    `${new Date().getSeconds()}-Rotaciones por mes`
                  );
                }}
              >
                Descargar Excel
              </Button>
            </Row>
          )}
        </Row>
        <Table
          ref={ref}
          dataSource={ReporteEvaluacionesAPI.data}
          columns={generateMapTable([
            "Residente",
            "Especialidad",
            "Lugar",
            "CampoFormacion",
            "Año",
            "Obs",
          ])}
        />
      </Card>
    </Container>
  );
};

export default RotacionesXMes;
