import React, { useEffect } from "react";
import { Div } from "util-styled-components";
import { Formulario } from "ts_formulario_model";
import { useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { message } from "antd";
import { TipoCentroSalud } from "static/constants";
const CrearCentroSalud = () => {
  const nav = useNavigate();
  const APICrearCentroSalud = useAxios(
    "POST",
    "/Crear-Establecimiento",
    {},
    false
  );

  useEffect(() => {
    if (APICrearCentroSalud.responseCode) {
      APICrearCentroSalud.responseCode === 200 &&
        message.success("Se ha creado el centro de salud");
      APICrearCentroSalud.responseCode === 400 &&
        message.error("No se ha creado el centro de salud");

      nav("/administrador/centros-de-salud");
    }

    return () => {};
  }, [APICrearCentroSalud.responseCode]);

  const guardarCS = (data) => {
    try {
      const params = {
        ...data,
        IdTipoEstablecimiento: data.TipoEstablecimiento[0].value,
      };
      APICrearCentroSalud.updateParams(params);
      APICrearCentroSalud.initRequest();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div width="92%" margin="auto">
      <Formulario onSubmit={guardarCS}>
        <Formulario.FormControls
          onCancel={() => {
            nav("/administrador/centros-de-salud");
          }}
          inputs={[
            {
              name: "NombreHospital",
              type: "input",
              customProps: {
                placeholder: "Nombre del Hospital",
              },
            },
            {
              name: "TipoEstablecimiento",
              type: "dropdown",
              optionsDropdown: TipoCentroSalud,
              optionLabel: "name",
              optionValue: "name",
            },
          ]}
        />
      </Formulario>
    </Div>
  );
};

export default CrearCentroSalud;
