import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 1em auto;
  padding: 0;

  .no-padding {
    padding: 0;
  }
`;
