import {
  faArrowRightToBracket,
  faBookReader,
  faCogs,
  faRibbon,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Menu } from "antd";
import { useUsuario } from "hooks/ContextUser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl, MenuItems } from "./menuItems";
function DrawerX({ visible, setVisible }) {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();
  const { logout, usuario } = useUsuario();
  const menuClick = (e) => {
    setCurrent(e.key);
    if (e.key === "logout") {
      navigate("/login");
      return logout();
    }
    navigate(getUrl(e.keyPath));
  };

  const generateMap = () => {
    const mapResidente = {
      label: "Residente",
      key: "residente",
      icon: <FontAwesomeIcon icon={faUserAlt} className="icon-primary" />,
      children: [
        {
          label: "Mi perfil",
          key: usuario.user.IdResidente,
          icon: (
            <FontAwesomeIcon
              icon={faArrowRightToBracket}
              className="icon-primary"
            />
          ),
        },
        {
          label: "Mis rotaciones",
          key: `mis-rotaciones/${usuario.user.IdResidente}`,
          icon: (
            <FontAwesomeIcon
              icon={faArrowRightToBracket}
              className="icon-primary"
            />
          ),
        },
      ],
    };
    const mapAdmin = {
      label: "Administrador",
      key: "administrador",
      icon: <FontAwesomeIcon icon={faCogs} className="icon-primary" />,
      children: [
        {
          label: "Lista de residentes",
          key: "residentes",
        },
        {
          label: "Especialidades",
          key: "especialidades",
        },
        {
          label: "Centros de salud",
          key: "centros-de-salud",
        },
      ],
    };

    if (usuario.user.idrol === 3) {
      return [
        mapResidente,
        {
          label: "Cerrar sesión",
          key: "logout",
        },
      ];
    }

    return [
      mapAdmin,
      {
        label: "Reportes",
        key: "reportes",
        icon: <FontAwesomeIcon icon={faBookReader} className="icon-primary" />,
        children: [
          {
            label: "Reporte de rotaciones",
            key: "rotaciones",
          },
          {
            label: "Reporte de rotaciones externas",
            key: "rotacionesExternas",
          },
          {
            label: "Reporte de rotaciones externas (Especialidad)",
            key: "rotacionesExternasEspecialidad",
          },
          {
            label: "Reporte de rotaciones por mes",
            key: "rotacionesXMes",
          },
        ],
      },
      {
        label: "Cerrar sesión",
        key: "logout",
      },
    ];
  };

  return (
    usuario &&
    usuario.user && (
      <Drawer
        title="Menu Principal"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 0, margin: 0 }}
      >
        <Menu
          mode="inline"
          selectedKeys={[current]}
          onClick={menuClick}
          items={generateMap()}
        />
      </Drawer>
    )
  );
}

export default DrawerX;
