import { green, red, volcano } from "@ant-design/colors";
import {
  faBone,
  faBook,
  faBookAtlas,
  faFilePdf,
  faHospital,
  faHospitalSymbol,
  faInfoCircle,
  faNoteSticky,
  faStethoscope,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Tag } from "antd";
import Item from "components/atoms/Item";
import { format } from "date-fns";
import React from "react";
import { Column, Grid, Heading, Row } from "util-styled-components";
import { getTipoSaludLabel } from "utils/getConstants";
import uf from "util-functions/src";

const CardRotacion = ({ data, key, noBorder = false }) => {
  return (
    <Card
      title={`Rotacion ${data.IdRotacion}`}
      style={{ borderColor: noBorder ? "transparent" : "#e8e8e8" }}
      extra={
        <Row>
          {data.URL_NOTA || data.NotaFinal ? (
            <Tag color="success">Evaluada</Tag>
          ) : (
            <Tag color="error">Sin Evaluar</Tag>
          )}
        </Row>
      }
    >
      <Column gap=".5em">
        <Grid gap=".5em">
          <Item icon={faHospital} label="Nombre" value={data.Nombre} />
          {data.CampoFormacion && (
            <Item
              icon={faStethoscope}
              label="Campo de Formacion"
              value={`${data.CampoFormacion}`}
            />
          )}
          <Item icon={faTimeline} label="Año" value={data.Periodo} />
          <Item
            icon={faTimeline}
            label="Fechas"
            value={`${uf.formatLocalTZ(data.FechaInicio, "dd/MM/yyyy")} ${
              data.FechaFin
                ? " - " + uf.formatLocalTZ(data.FechaFin, "dd/MM/yyyy")
                : ""
            }`}
          />
          {/* <Item icon={faInfoCircle} label="Condición" value="Cautivo" /> */}
          <Item
            icon={faHospitalSymbol}
            label="Tipo de Establecimiento"
            value={getTipoSaludLabel(data.IdTipoEstablecimiento)}
          />
          {data.Observaciones && (
            <Item
              icon={faBook}
              label="Observaciones"
              value={data.Observaciones}
            />
          )}
          {data.NotaFinal && (
            <Item
              colorSchema={green}
              icon={faNoteSticky}
              label="Nota final"
              value={data.NotaFinal}
            />
          )}
        </Grid>
        {data.URL_NOTA && (
          <Row alignSelf="flex-end" margin="20px 0 0 0" gap="15px">
            <a href={data.URL_NOTA} target="_blank">
              <Button type="primary">
                <Row gap="15px">
                  Ver nota final
                  <FontAwesomeIcon fontSize="20px" icon={faFilePdf} />
                </Row>
              </Button>
            </a>
          </Row>
        )}
      </Column>
    </Card>
  );
};

export default CardRotacion;
