import {
  faAngleLeft,
  faAngleRight,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, Col } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Column, Row, Div, Heading, Typography } from 'util-styled-components'
import AVATAR from '../../assets/img/doctor.png'
const residentesURI = '/administrador/residentes'
const InformacionResidente = ({
  data,
  extra,
  extraInfo,
  margin = '10px 0',
  borderStyle = 'dashed',
  inactiveButtonMoreInfo = false
}) => {
  const nav = useNavigate()

  const navigate = url => {
    nav(`${residentesURI}${url}`, { state: { data } })
  }

  return (
    <Card bodyStyle={{ padding: '15px 10px' }} style={{ margin, borderStyle }}>
      {data && (
        <Row justifyContent='space-between' responsiveReorder>
          <Row gap='15px' alignSelf='flex-start'>
            <Avatar src={AVATAR} alt='...' size='large' />
            <Column alignItems='flex-start'>
              <Heading
                variant='h4'
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  color: 'black',
                  cursor: 'pointer'
                }}
                onClick={() => nav(`/residente/${data.IdResidente}`)}
              >
                {data.Nombres?.toLowerCase()} {data.Apellidos?.toLowerCase()}
              </Heading>
              <Row gap='15px'>
                <Typography variant='span'>CMP: {data.CMP}</Typography>
                <Typography variant='span'>DNI: {data.DNI}</Typography>
              </Row>
              <Typography variant='span'>
                Especialidad: {data.NombreEspecialidad}
              </Typography>
            </Column>
          </Row>
          <Row margin='10px 0'>
            {!inactiveButtonMoreInfo && (
              <Button
                type='link'
                onClick={() => nav(`/residente/${data.IdResidente}`)}
              >
                <Row gap='10px'>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Mas información
                </Row>
              </Button>
            )}
            {extra}
          </Row>
        </Row>
      )}
    </Card>
  )
}

export default InformacionResidente
