import { Modal, Spin } from "antd";
import Layout from "components/skeleton/Layout";
import RoutesX from "components/skeleton/Layout/Routes";
import { useUsuario } from "hooks/ContextUser";
import { useLoading } from "hooks/LoadingContext";
import { Login } from "pages";
import { Route, Routes } from "react-router-dom";
import { Row } from "util-styled-components";

const App = () => {
  const { userIsLogged } = useUsuario();
  const { activeModalLoading } = useLoading();
  return (
    <div>
      {!userIsLogged && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
        </Routes>
      )}
      {userIsLogged && (
        <Layout>
          <RoutesX />
        </Layout>
      )}
      <Modal title="Cargando" visible={activeModalLoading} footer={null}>
        <Row gap="20px">
          <Spin />
          <p>Espere un momento...</p>
        </Row>
      </Modal>
    </div>
  );
};

export default App;
