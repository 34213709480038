import { Button, Checkbox, Input, message, Table } from "antd";
import { Container } from "components/skeleton/Container";
import Layout from "components/skeleton/Layout";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { Row, Heading } from "util-styled-components";
import { generateMapTable } from "utils/tables";
import { useNavigate } from "react-router-dom";
import { fetchPost } from "utils/simpleAxios";
import { useUsuario } from "hooks/ContextUser";
const cargar = "/Cargar-Especialidad";

const ActivarEspecialidad = () => {
  const { tokenInterceptor } = useUsuario();
  const { data } = useAxios("GET", "/Listar-Especialidades-BASE-SIGH");
  const [listaActivar, setListaActivar] = useState([]);
  const nav = useNavigate();

  const activeButtonSelector = (active, code) => {
    if (active) {
      setListaActivar([...listaActivar, code]);
    } else {
      setListaActivar(listaActivar.filter((el) => el !== code));
    }
  };

  const getCheck = (id) => {
    return !!listaActivar.includes(id);
  };

  const guardarSeleccion = () => {
    listaActivar.forEach(async (id) => {
      await fetchPost(cargar, { id }, tokenInterceptor);
    });
    setListaActivar([]);
    message.success("Se ha guardado la selección");
    nav("/administrador/especialidades");
  };

  return (
    <Container>
      <Row justifyContent="space-between">
        <Heading variant="h2">
          Marcar con un check las especialidades que desea activar
        </Heading>
        {listaActivar.length > 0 && (
          <Button type="primary" onClick={guardarSeleccion}>
            Guardar
          </Button>
        )}
      </Row>
      <Table
        dataSource={
          data ? data.filter((el) => typeof el.IdActivo !== "number") : []
        }
        columns={generateMapTable([
          {
            dataIndex: "IdEspecialidad",
            title: "ID",
          },
          "Nombre",
          {
            dataIndex: "Activo",
            render: (_, row) => (
              <Checkbox
                checked={getCheck(row.IdEspecialidad)}
                onChange={({ target }) =>
                  activeButtonSelector(target.checked, row.IdEspecialidad)
                }
              />
            ),
          },
        ])}
      />
    </Container>
  );
};

export default ActivarEspecialidad;
