import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { Descriptions, message } from "antd";
import { Formulario } from "ts_formulario_model";
import { MapRotation } from "../MapFormulario";
import { useNavigate, useParams } from "react-router-dom";
import { Div, Typography } from "util-styled-components";
import momentTZ from "utils/momentTZ";
import InformacionResidente from "components/molecules/InformacionResidente";
import { useDataActualizadaResidente } from "hooks/useDataActualizadaResidente";
import uf from "util-functions/src/core/strings";
import DragDrop from "components/molecules/DragDrop";
const residentesURI = "/administrador/residentes";
const RotarResidente = () => {
  const [fileUploaded, setFileUploaded] = useState(null);
  const Establecimientos = useAxios("GET", "/Establecimientos-activos");
  const Especialidades = useAxios("GET", "/Listar-Especialidades");
  const APICrearRotacion = useAxios("POST", "/Crear-rotacion", {}, false);
  const { id } = useParams();
  const da = useDataActualizadaResidente(id);
  const nav = useNavigate();

  const getURLForFIleUploaded = () => {
    try {
      const url = fileUploaded.response.url;
      if (uf.isUrl(url)) {
        return url;
      }
      return "";
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (APICrearRotacion.responseCode) {
      APICrearRotacion.responseCode === 200 &&
        message.success("Se cargaron las rotaciones");
      APICrearRotacion.responseCode === 400 &&
        message.error("No se cargaron las rotaciones");
      navigate(`/rotaciones/${id}`);
    }

    return () => {};
  }, [APICrearRotacion.responseCode]);

  const navigate = (url) => {
    if (da) {
      nav(`${residentesURI}${url}`, { state: { data: da.data } });
    }
  };

  const guardarRotacion = (data) => {
    try {
      const params = {
        ...data,
        IdEspecialidad: da.data.IdEspecialidad,
        IdHospRot: data.HospRotacion[0].IdRegistro,
        IdResidente: da.data.IdResidente,
        FechaFin: momentTZ.getDateT00Z(data.FechaFin, "YYYY-MM-DD"),
        FechaInicio: momentTZ.getDateT00Z(data.FechaInicio, "YYYY-MM-DD"),
        Periodo: data["Año"][0].value,
        CampoFormacion: data["CampoDeFormación"],
        NOTA_URL: fileUploaded ? getURLForFIleUploaded() : "",
      };
      APICrearRotacion.updateParams(params);
      APICrearRotacion.initRequest();
    } catch (error) {
      message.error("Ocurrio un error al cargar las rotaciones");
      console.error(error);
    }
  };

  return (
    <Div width="92%" margin={"auto"}>
      {da.data && (
        <Formulario onSubmit={guardarRotacion}>
          <Formulario.Title>Rotar un residente</Formulario.Title>
          <InformacionResidente data={da.data} />
          <Formulario.FormControls
            onCancel={() => {
              navigate(`/rotaciones/${id}`);
            }}
            inputs={[
              {
                name: "HospRotacion",
                type: "dropdown",
                required: true,
                optionsDropdown: Establecimientos.data,
                optionValue: "Nombre",
                optionLabel: "Nombre",
                customProps: {
                  required: true,
                },
              },
              ...MapRotation,
            ]}
          >
            <Div width={"100%"} margin="20px auto">
              <Typography variant="p">Subir archivo de notas</Typography>
              <DragDrop uploadedFile={(file) => setFileUploaded(file)} />
            </Div>
          </Formulario.FormControls>
        </Formulario>
      )}
    </Div>
  );
};

export default RotarResidente;
