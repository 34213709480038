import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { Button, Descriptions, message } from "antd";
import { Formulario } from "ts_formulario_model";
import { MapRotation } from "../MapFormulario";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Div, Typography } from "util-styled-components";
import momentTZ from "utils/momentTZ";
import InformacionResidente from "components/molecules/InformacionResidente";
import { useDataActualizadaResidente } from "hooks/useDataActualizadaResidente";
import uf from "util-functions/src/core/strings";
import ufTime from "util-functions/src/core/time";
import DragDrop from "components/molecules/DragDrop";
import { Anios } from "static/constants";
import moment from "moment";
const required = true;
const residentesURI = "/administrador/residentes";
const EditarRotacion = () => {
  const [fileUploaded, setFileUploaded] = useState(null);
  const Establecimientos = useAxios("GET", "/Establecimientos-activos");
  const APIEditarRotacion = useAxios("POST", "/Actualizar-rotacion", {}, false);
  const { id } = useParams();
  const { state } = useLocation();
  const da = useDataActualizadaResidente(id);
  const nav = useNavigate();

  const getURLForFIleUploaded = () => {
    try {
      const url = fileUploaded.response.url;
      if (uf.isUrl(url)) {
        return url;
      }
      return "";
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (APIEditarRotacion.responseCode) {
      APIEditarRotacion.responseCode === 200 &&
        message.success("Se cargaron las rotaciones");
      APIEditarRotacion.responseCode === 400 &&
        message.error("No se cargaron las rotaciones");
      navigate(`/rotaciones/${id}`);
    }

    return () => {};
  }, [APIEditarRotacion.responseCode]);

  const navigate = (url) => {
    if (da) {
      nav(`${residentesURI}${url}`, { state: { data: da.data } });
    }
  };

  const guardarRotacion = (data) => {
    try {
      const params = {
        ...data,
        IdEspecialidad: da.data.IdEspecialidad,
        IdHospRot: data.HospRotacion[0].IdRegistro,
        IdResidente: da.data.IdResidente,
        FechaFin: momentTZ.getDateT00Z(data.FechaFin, "YYYY-MM-DD"),
        FechaInicio: momentTZ.getDateT00Z(data.FechaInicio, "YYYY-MM-DD"),
        Periodo: data["Año"][0].value,
        CampoFormacion: data["CampoDeFormación"],
        NOTA_URL: fileUploaded
          ? getURLForFIleUploaded()
          : state.URL_NOTA
          ? state.URL_NOTA
          : "",
        IdRotacion: state.IdRotacion,
      };
      APIEditarRotacion.updateParams(params);
      APIEditarRotacion.initRequest();
    } catch (error) {
      message.error("Ocurrio un error al cargar las rotaciones");
      console.error(error);
    }
  };

  return (
    <Div width="92%" margin={"auto"}>
      {da.data && state && Establecimientos.data.length > 0 && (
        <Formulario onSubmit={guardarRotacion}>
          <Formulario.Title>Rotar un residente</Formulario.Title>
          <InformacionResidente data={da.data} />
          <Formulario.FormControls
            onCancel={() => {
              navigate(`/rotaciones/${id}`);
            }}
            inputs={[
              {
                name: "HospRotacion",
                type: "dropdown",
                required: true,
                optionsDropdown: Establecimientos.data,
                optionValue: "Nombre",
                optionLabel: "Nombre",
                defaultValue: Establecimientos.data.filter(
                  (el) => el.IdRegistro === state.IdHospRot
                ),
                customProps: {
                  required: true,
                },
              },
              {
                name: "CampoDeFormación",
                type: "input",
                defaultValue: state.CampoFormacion,
                customProps: {
                  placeholder: "Ingrese campo de formación",
                },
              },
              {
                name: "FechaInicio",
                type: "calendar",
                defaultValue: moment(
                  new Date(
                    ufTime.formatLocalTZ(
                      state.FechaInicio,
                      "MM/dd/yyyy hh:mm:ss a"
                    )
                  )
                ),
                required,
                customProps: {
                  required,
                },
              },
              {
                name: "FechaFin",
                type: "calendar",
                required,
                defaultValue: moment(
                  new Date(
                    ufTime.formatLocalTZ(
                      state.FechaFin,
                      "MM/dd/yyyy hh:mm:ss a"
                    )
                  )
                ),
                customProps: {
                  required,
                },
              },
              {
                name: "Año",
                type: "dropdown",
                optionsDropdown: Anios,
                defaultValue: Anios.filter((el) => el.value === state.Periodo),
                optionValue: "name",
                optionLabel: "name",
              },
              {
                name: "Nota",
                type: "input",
                defaultValue: state.NotaFinal ? state.NotaFinal : "",
                customProps: {
                  placeholder: "Ingrese nota",
                  type: "number",
                  min: "0",
                  step: "0.1",
                  max: "20",
                },
              },
              {
                name: "Observaciones",
                defaultValue: state.Observaciones ? state.Observaciones : "",
                type: "input-description",
              },
            ]}
          >
            <Div width={"100%"} margin="20px auto">
              <Typography variant="p">Actualizar archivo de notas</Typography>
              <DragDrop uploadedFile={(file) => setFileUploaded(file)} />
              {state.URL_NOTA && (
                <a href={state.URL_NOTA} target="_blank">
                  <Button type="link">Archivo cargado: {state.URL_NOTA}</Button>
                </a>
              )}
            </Div>
          </Formulario.FormControls>
        </Formulario>
      )}
    </Div>
  );
};

export default EditarRotacion;
