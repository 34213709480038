import { blue, geekblue, green } from '@ant-design/colors'
import {
  faHospital,
  faHospitalSymbol,
  faInfoCircle,
  faMailBulk,
  faMobilePhone,
  faStethoscope,
  faTimeline
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Card, Progress, Tag } from 'antd'
import Item from 'components/atoms/Item'
import { Container } from 'components/skeleton/Container'
import { format } from 'date-fns'
import { useDataActualizadaResidente } from 'hooks/useDataActualizadaResidente'
import React from 'react'
import { useParams } from 'react-router-dom'
import uf from 'util-functions/src'
import { Column, Heading, Grid, Row, Typography } from 'util-styled-components'
import AVATAR from '../../../assets/img/doctor.png'

const MiPerfil = () => {
  let { id } = useParams()

  const { data } = useDataActualizadaResidente(id)
  if (data) {
    return (
      <Container>
        <Card>
          <Row justifyContent='space-between'>
            <Row gap='1em'>
              <Avatar
                src={AVATAR}
                alt='...'
                size={'large'}
                style={{ backgroundColor: green[1] }}
              />
              <Column>
                <Row>
                  <Heading
                    variant='h3'
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      color: 'black'
                    }}
                  >
                    {`${data.Nombres} ${data.Apellidos}`}
                  </Heading>
                </Row>
                <Row gap='15px'>
                  <Typography variant='span'>CMP: {data.CMP}</Typography>
                  <Typography variant='span'>DNI: {data.DNI}</Typography>
                </Row>
                {data.NombreEspecialidad}
              </Column>
            </Row>
            <Row gap='.5em'>
              <Typography variant='span'>Estado:</Typography>
              <Tag color={data.Estado === 1 ? 'success' : 'error'}>
                {data.Estado === 1 ? 'Activo' : 'Inactivo'}
              </Tag>
            </Row>
          </Row>
        </Card>
        {data.IdRotacionActual && (
          <Card
            style={{ margin: '15px 0' }}
            title='Establecimiento Actual'
            extra={
              <Progress
                percent={100}
                steps={5}
                size='small'
                strokeColor='#52c41a'
              />
            }
          >
            <Grid gap='.5em'>
              <Item
                icon={faHospital}
                label='Nombre'
                value={data.NombreHosp ?? 'NO INDICADO'}
              />
              <Item
                icon={faStethoscope}
                label='Campo de formación'
                value={`${data.CampoFormacion ?? 'N/A'}`}
              />
              <Item
                icon={faTimeline}
                label='Año'
                value={data.Periodo ?? 'N/A'}
              />
              <Item
                icon={faTimeline}
                label='Fechas'
                value={`${
                  data.FechaInicio
                    ? uf.formatLocalTZ(data.FechaInicio, 'dd/MM/yyyy')
                    : '----'
                } ${
                  data.FechaFin
                    ? data.FechaFin
                      ? ' - ' + uf.formatLocalTZ(data.FechaFin, 'dd/MM/yyyy')
                      : ''
                    : ' - ----'
                }`}
              />
              <Item
                icon={faInfoCircle}
                label='Condición'
                value={data.NombreCondicion}
              />
              <Item
                icon={faHospitalSymbol}
                label='Tipo de Establecimiento'
                value={
                  data.IdTipoEstablecimiento
                    ? data.IdTipoEstablecimiento?.trim() === 'M'
                      ? 'Minsa'
                      : 'No Minsa'
                    : '--------'
                }
              />
            </Grid>
          </Card>
        )}
        <Card title='Contacto'>
          <Item icon={faMobilePhone} label='Telefono' value={data.Celular} />
          <Item icon={faMailBulk} label='Email' value={data.Email} />
        </Card>
      </Container>
    )
  }
}

export default MiPerfil
