import {
  faArrowRightToBracket,
  faRibbon,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getUrl = (keyPath = []) => {
  const reverse = keyPath.reverse();
  return reverse.reduce(
    (acc, curr) => `${acc}${curr === "rc-menu-more" ? "" : curr}/`,
    ""
  );
};

export const MenuItems = [
  {
    label: "Residente",
    key: "residente",
    icon: <FontAwesomeIcon icon={faUserAlt} className="icon-primary" />,
    children: [
      {
        label: "Mi perfil",
        key: "1",
        icon: (
          <FontAwesomeIcon
            icon={faArrowRightToBracket}
            className="icon-primary"
          />
        ),
      },
      {
        label: "Mis rotaciones",
        key: "mis-rotaciones/1",
        icon: (
          <FontAwesomeIcon
            icon={faArrowRightToBracket}
            className="icon-primary"
          />
        ),
      },
    ],
  },
  {
    label: "Administrador",
    key: "administrador",
    icon: <FontAwesomeIcon icon={faRibbon} className="icon-primary" />,
    children: [
      {
        label: "Lista de residentes",
        key: "residentes",
      },
      {
        label: "Especialidades",
        key: "especialidades",
      },
      {
        label: "Centros de salud",
        key: "centros-de-salud",
      },
    ],
  },
  {
    label: "Reportes",
    key: "reportes",
    icon: <FontAwesomeIcon icon={faRibbon} className="icon-primary" />,
    children: [
      {
        label: "Reporte de rotaciones",
        key: "rotaciones",
      },
    ],
  },
  {
    label: "Cerrar sesión",
    key: "logout",
  },
];
