import { Table } from "antd";
import { Container } from "components/skeleton/Container";
import React from "react";

const VerNotasResidentes = () => {
  return (
    <Container>
      <Table columns={[]} dataSource={[]} />
    </Container>
  );
};

export default VerNotasResidentes;
