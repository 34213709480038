import styled from "styled-components";

export const Portada = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 80%,
      rgba(253, 253, 253, 0.9) 90%,
      rgba(255, 255, 255, 1) 100%
    );
  } */
  svg {
    width: 80%;
    height: auto;
    object-fit: cover;
  }
`;

export const FormLogin = styled.form`
  width: 50%;
  padding: 3em 1.5em;
  background-color: #fefefe;
  @media (max-width: 768px) {
    width: 96%;
  }
`;

export const FormLoginFooter = styled.div`
  display: flex;
  align-self: flex-end;
  font-size: 10pt;
  font-family: ${({ theme }) => theme.fonts.fontFamily};
`;
