import { Button, Card, Table } from "antd";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React, { useRef, useState } from "react";
import { Formulario } from "ts_formulario_model";
import { Heading, Row } from "util-styled-components";
import momentTZ from "utils/momentTZ";
import { generateMapTable } from "utils/tables";
import uf from "util-functions/src";
import SaveAsExcel from "Exports/Excel";
import { GenerarPDFReporteResidente } from "Exports/Reporte";
const ReporteEvaluacionesExternasEspecialidad = () => {
  const ref = useRef(null);

  const [Selecccionado, setSelecccionado] = useState({});
  const ListaEspecialidades = useAxios("GET", "/Listar-Especialidades");
  const ReporteEvaluacionesAPI = useAxios(
    "POST",
    "/ReporteEvaluacionesExternasEspecialidad",
    {},
    false
  );

  const generateReporte = () => {
    GenerarPDFReporteResidente(
      ReporteEvaluacionesAPI.data,
      Selecccionado,
      true
    );
  };

  const Buscar = ({ FechaFin, FechaInicio, Especialidad }) => {
    try {
      const params = {
        id: Especialidad[0].IdEspecialidad,
        FechaInicio: momentTZ.getDateT00Z(FechaInicio, "YYYY-MM-DD"),
        FechaFin: momentTZ.getDateT00Z(FechaFin, "YYYY-MM-DD"),
      };

      setSelecccionado({
        ...params,
        Residente: Especialidad[0],
      });

      ReporteEvaluacionesAPI.updateParams(params);
      ReporteEvaluacionesAPI.initRequest();
    } catch (error) {}
  };

  return (
    <Container>
      <Card style={{ marginBottom: 15 }}>
        <Heading variant="h3">Reporte de evaluaciones</Heading>
        <Formulario onSubmit={Buscar}>
          <Formulario.FormControls
            inputs={[
              {
                name: "Especialidad",
                type: "dropdown",
                optionsDropdown: ListaEspecialidades.data,
                optionValue: "NombreEspecialidad",
                optionLabel: "NombreEspecialidad",
                required: true,
                customProps: {
                  required: true,
                },
              },
              {
                name: "FechaInicio",
                type: "calendar",
                required: true,
                customProps: {
                  required: true,
                },
              },
              {
                name: "FechaFin",
                type: "calendar",
                required: true,
                customProps: {
                  required: true,
                },
              },
            ]}
            submitLabel="Buscar"
            cancelLabel="<>"
          />
        </Formulario>
      </Card>
      <Card>
        <Row justifyContent="space-between" margin="0 0 15px 0">
          <Heading>Evaluaciones</Heading>
          {ReporteEvaluacionesAPI.data.length > 0 && (
            <Row justifyContent="flex-end" gap="30px">
              <Button
                type="link"
                onClick={() => {
                  SaveAsExcel(
                    ReporteEvaluacionesAPI.data.map((el) => ({
                      ...el,
                      FechaInicio: uf.formatLocalTZ(
                        el.FechaInicio,
                        "dd/MM/yyyy"
                      ),
                      FechaFin: uf.formatLocalTZ(el.FechaFin, "dd/MM/yyyy"),
                    })),
                    `${new Date().getSeconds()}-Reporte de Evaluaciones`
                  );
                }}
              >
                Descargar Excel
              </Button>
              <Button
                type="link"
                onClick={() => {
                  generateReporte();
                }}
              >
                Descargar PDF
              </Button>
            </Row>
          )}
        </Row>
        <Table
          ref={ref}
          dataSource={ReporteEvaluacionesAPI.data}
          columns={generateMapTable([
            "Lugar",
            "Especialidad",
            {
              title: "Residentes",
              dataIndex: "NombresCompletos",
            },
            {
              dataIndex: "FechaInicio",
              render: (_, row) =>
                row.FechaInicio
                  ? uf.formatLocalTZ(row.FechaInicio, "dd/MM/yyyy")
                  : "-------",
            },
            {
              dataIndex: "FechaFin",
              render: (_, row) =>
                row.FechaFin
                  ? uf.formatLocalTZ(row.FechaFin, "dd/MM/yyyy")
                  : "-------",
            },
            "AÑO",
            "Nota",
          ])}
        />
      </Card>
    </Container>
  );
};

export default ReporteEvaluacionesExternasEspecialidad;
