import { Anios } from "static/constants";

const required = true;

export const MapFormulario = [
  {
    name: "DNI",
    type: "input",
    required,
    customProps: {
      placeholder: "DNI",
      type: "number",
      required,
    },
  },
  {
    name: "Nombres",
    type: "input",
    required,
    customProps: {
      placeholder: "Ingrese nombres",
      required,
    },
  },
  {
    name: "Apellidos",
    type: "input",
    required,
    customProps: {
      placeholder: "Ingrese apellidos",
      required,
    },
  },
  {
    name: "CMP",
    type: "input",
    customProps: {
      placeholder: "CMP",
      type: "number",
    },
  },
  {
    name: "FechaIngreso",
    type: "calendar",
    required,
    customProps: {
      required,
    },
  },
  {
    name: "CondicionResidente",
    type: "dropdown",
    required,
    optionsDropdown: [
      { id: 1, name: "Cautivo" },
      { id: 2, name: "Libre" },
    ],
    optionValue: "name",
    optionLabel: "name",
    customProps: {
      required: true,
    },
  },
  {
    name: "Email",
    type: "input",
    customProps: {
      placeholder: "Ingrese email",
      type: "email",
    },
  },
  {
    name: "Celular",
    type: "input",
    customProps: {
      placeholder: "Ingrese telefono celular",
    },
  },
  {
    name: "Dirección",
    type: "input",
    customProps: {
      placeholder: "Ingrese dirección",
    },
  },
];

export const MapRotation = [
  {
    name: "CampoDeFormación",
    type: "input",
    customProps: {
      placeholder: "Ingrese campo de formación",
    },
  },
  {
    name: "FechaInicio",
    type: "calendar",
    required,
    customProps: {
      required,
    },
  },
  {
    name: "FechaFin",
    type: "calendar",
    required,
    customProps: {
      required,
    },
  },
  {
    name: "Año",
    type: "dropdown",
    optionsDropdown: Anios,
    optionValue: "name",
    optionLabel: "name",
  },
  {
    name: "Nota",
    type: "input",
    customProps: {
      placeholder: "Ingrese nota",
      type: "number",
      step: "0.1",
      min: "0",
      max: "20",
    },
  },
  {
    name: "Observaciones",
    type: "input-description",
  },
];

export const CargarNotaMap = [
  {
    name: "NotaFinal",
    type: "input",
    required: true,
    customProps: {
      required: true,
      type: "number",
      step: "0.1",
      min: "0",
      max: "20",
    },
  },
  {
    name: "Observaciones",
    type: "input",
  },
];
