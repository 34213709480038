import styled from "styled-components";
import { Row } from "util-styled-components";
import { blue, geekblue } from "@ant-design/colors";

export const UserName = styled(Row)`
  background-color: ${geekblue[0]};
  padding: 0.5em 1em;
  border-radius: 0.5em;
  cursor: pointer;
  svg {
    color: ${blue.primary};
  }
`;
