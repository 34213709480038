import axios from 'axios'
import AxiosTokenProvider from 'axios-token-interceptor'
import { url, params as PATH_HOST } from 'hooks/useAxios'

export const fetchPost = async (uri, params, tokenInterceptor = '') => {
  const _itc = createInstance(url, tokenInterceptor)
  try {
    return await _itc.post(PATH_HOST, {
      method: 'POST',
      route: uri,
      params
    })
  } catch (error) {
    throw error
  }
}

export const createInstance = (baseURL, tokenInterceptor) => {
  const instance = axios.create({
    baseURL
  })
  if (tokenInterceptor) {
    instance.interceptors.request.use(
      AxiosTokenProvider({
        getToken: () => tokenInterceptor
      })
    )
  }
  return instance
}
