import Axios from 'axios'
import { saveAs } from 'file-saver'

const baseUrl = 'https://elzeein.com:5001'

export const GenerarPDFReporteResidente = (
  table1,
  Selecccionado,
  displayName = false
) => {
  Axios.post(`${baseUrl}/api/residentado/create-pdf`, {
    data: {
      header: Selecccionado,
      tabla: table1,
      displayName
    }
  })
    .then(() =>
      Axios.get(`${baseUrl}/api/residentado/fetch-pdf`, {
        responseType: 'blob'
      })
    )
    .then(res => {
      Axios.get(`${baseUrl}/api/residentado/remove-pdf`)
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
      saveAs(pdfBlob, `Reporte de evaluaciones.pdf`)
    })
}
