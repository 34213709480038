import { blue, geekblue } from "@ant-design/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "antd";
import React from "react";
import { Column, Row, Typography } from "util-styled-components";

const Item = ({ icon, label, value, colorSchema = geekblue, link = false }) => {
  return (
    <Row gap=".5em">
      <Avatar style={{ backgroundColor: colorSchema[0] }}>
        <FontAwesomeIcon icon={icon} color={colorSchema.primary} />
      </Avatar>
      <Row gap=".5em">
        {label && (
          <Typography
            variant="span"
            style={{
              fontWeight: "bold",
              color: link ? colorSchema.primary : "#333",
            }}
          >
            {label}:
          </Typography>
        )}
        <Typography variant="span" color={link && blue.primary}>
          {value}
        </Typography>
      </Row>
    </Row>
  );
};

export default Item;
