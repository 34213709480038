import styled from "styled-components";

export const Button = styled.button`
  padding: 0.375rem 0.75rem;
  ${({ fullSize }) => fullSize && `width: 100%;`}
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  ${(props) =>
    props.primary &&
    `
    background-color: ${
      props.complementary
        ? props.theme.colors.complementary
        : props.theme.colors.primary
    };
    color: ${props.theme.colors.textInverse};
    transition: all 0.3s ease;
    &:hover {
      background-color: ${
        props.complementary
          ? props.theme.colors.complementary
          : props.theme.colors.primaryHover
      };
      border-color: ${props.theme.colors.border};
    }
  `}
  ${(props) =>
    props.ghost &&
    `
    background-color: transparent;
    color: ${
      props.complementary
        ? props.theme.colors.complementary
        : props.theme.colors.primary
    };
    border: 1px solid ${
      props.complementary
        ? props.theme.colors.complementary
        : props.theme.colors.primary
    };
    transition: all 0.3s ease;
    &:hover {
      background-color: ${
        props.complementary
          ? props.theme.colors.complementary
          : props.theme.colors.primary
      };
      color: ${props.theme.colors.textInverse};
      border-color: ${props.theme.colors.border};
    }
  `};

  ${(props) => props.disabled && `opacity: 0.5;`}
  ${(props) => props.loading && `opacity: 0.5;`}
  ${(props) => props.disabled && `cursor: not-allowed;`}
  ${(props) => props.loading && `cursor: not-allowed;`}


  ${(props) =>
    props.link &&
    `
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: ${
      props.complementary
        ? props.theme.colors.complementary
        : props.theme.colors.primary
    };
    &:hover {
      color: ${props.theme.colors.primaryHover};
    }
  `}
`;
