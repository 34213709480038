import { message } from "antd";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formulario } from "ts_formulario_model";
import { Div } from "util-styled-components";
import momentTZ from "utils/momentTZ";
import { MapFormulario } from "./MapFormulario";
import ufTime from "util-functions/src/core/time";
const required = true;

const Condiciones = [
  { id: 1, name: "Cautivo" },
  { id: 2, name: "Libre" },
];

const EditarResidente = () => {
  const Especialidades = useAxios("GET", "/Listar-Especialidades");
  const APIEditarResidente = useAxios(
    "POST",
    "/Actualizar-residente",
    {},
    false
  );
  const nav = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (APIEditarResidente.responseCode) {
      APIEditarResidente.responseCode === 200 &&
        message.success("Se cargó el residente");
      APIEditarResidente.responseCode === 400 &&
        message.error("No se cargó el residente");
      nav("/administrador/residentes");
    }

    return () => {};
  }, [APIEditarResidente.responseCode]);

  const guardarResidente = (data) => {
    try {
      const params = {
        ...data,
        IdEspecialidad: data.Especialidad[0].IdEspecialidad,
        Condicion: data.CondicionResidente[0].id,
        FechaIngreso: momentTZ.getDateT00Z(data.FechaIngreso, "YYYY-MM-DD"),
        Domicilio: data["Dirección"],
        IdResidente: state.IdResidente,
      };
      APIEditarResidente.updateParams(params);
      APIEditarResidente.initRequest();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div width="92%" margin="auto">
      {state && Especialidades.data.length > 0 && (
        <Formulario onSubmit={guardarResidente}>
          <Formulario.Title>Editar residente</Formulario.Title>
          <Formulario.FormControls
            onCancel={() => {
              nav("/administrador/residentes");
            }}
            inputs={[
              {
                name: "HospitalProcedencia",
                type: "input",
                required: true,
                defaultValue: state.HospitalProcedencia,
                customProps: {
                  placeholder: "Ingrese el nombre del hospital",
                  required: true,
                },
              },
              {
                name: "Especialidad",
                type: "dropdown",
                required: true,
                optionsDropdown: Especialidades.data,
                defaultValue: Especialidades.data.filter(
                  (el) => el.IdEspecialidad === state.IdEspecialidad
                ),
                optionValue: "NombreEspecialidad",
                optionLabel: "NombreEspecialidad",
                customProps: {
                  required: true,
                },
              },
              {
                name: "DNI",
                type: "input",
                required,
                defaultValue: state.DNI,
                customProps: {
                  placeholder: "DNI",
                  type: "number",
                  required,
                },
              },
              {
                name: "Nombres",
                type: "input",
                required,
                defaultValue: state.Nombres,
                customProps: {
                  placeholder: "Ingrese nombres",
                  required,
                },
              },
              {
                name: "Apellidos",
                type: "input",
                required,
                defaultValue: state.Apellidos,
                customProps: {
                  placeholder: "Ingrese apellidos",
                  required,
                },
              },
              {
                name: "CMP",
                type: "input",
                defaultValue: state.CMP,
                customProps: {
                  placeholder: "CMP",
                  type: "number",
                },
              },
              {
                name: "FechaIngreso",
                type: "calendar",
                required,
                defaultValue: moment(
                  new Date(
                    ufTime.formatLocalTZ(
                      state.FechaIngreso,
                      "MM/dd/yyyy hh:mm:ss a"
                    )
                  )
                ),
                customProps: {
                  required,
                },
              },
              {
                name: "CondicionResidente",
                type: "dropdown",
                required,
                optionsDropdown: Condiciones,
                defaultValue: Condiciones.filter(
                  (el) => el.id === state.Condicion
                ),
                optionValue: "name",
                optionLabel: "name",
                customProps: {
                  required: true,
                },
              },
              {
                name: "Email",
                type: "input",
                defaultValue: state.Email,
                customProps: {
                  placeholder: "Ingrese email",
                  type: "email",
                },
              },
              {
                name: "Celular",
                type: "input",
                defaultValue: state.Celular,
                customProps: {
                  placeholder: "Ingrese telefono celular",
                },
              },
              {
                name: "Dirección",
                type: "input",
                defaultValue: state.Domicilio,
                customProps: {
                  placeholder: "Ingrese dirección",
                },
              },
            ]}
          />
        </Formulario>
      )}
    </Div>
  );
};

export default EditarResidente;
