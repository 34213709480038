import { Button, Table } from "antd";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Heading } from "util-styled-components";
import { generateMapTable } from "utils/tables";

const ListarEspecialidades = () => {
  const { data } = useAxios("GET", "/Listar-Especialidades");
  const navigate = useNavigate();

  const active = () => {
    navigate("activar");
  };

  return (
    <Container>
      <Row justifyContent="space-between">
        <Heading variant="h2">Lista de Especialidades</Heading>
        <Button onClick={active}>Activar especialidades</Button>
      </Row>
      <Table
        dataSource={data ? data : []}
        columns={generateMapTable([
          {
            dataIndex: "IdEspecialidad",
            title: "ID",
          },
          "NombreEspecialidad",
        ])}
      />
    </Container>
  );
};

export default ListarEspecialidades;
