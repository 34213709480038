import { useEffect } from "react";
import useAxios from "./useAxios";

export const useDataActualizadaResidente = (id) => {
  const APIResidenteActualizado = useAxios("POST", "/Residente", {}, false);

  useEffect(() => {
    if (id) {
      APIResidenteActualizado.updateParams({ id });
      APIResidenteActualizado.initRequest();
    }
  }, [id]);

  return {
    data:
      APIResidenteActualizado.data.length > 0
        ? APIResidenteActualizado.data[0]
        : undefined,
    actualizar: (_id) => (id = _id),
  };
};
