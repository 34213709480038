import React from "react";
import { Flex, Row, Column, RowWrap } from "./Flex";
import { Grid } from "./Grid";

const SelectorDisplays = ({
  type,
  gap = "10px",
  cols = 2,
  children,
  rest,
}: any) => {
  switch (type) {
    case "grid":
      return (
        <Grid gap={gap} cols={cols} {...rest}>
          {children}
        </Grid>
      );

    case "flex":
      return (
        <Flex responsiveReorder gap={gap} {...rest}>
          {children}
        </Flex>
      );

    case "column":
      return (
        <Column responsiveReorder gap={gap} {...rest}>
          {children}
        </Column>
      );

    case "row":
      return (
        <Row responsiveReorder gap={gap} {...rest}>
          {children}
        </Row>
      );
    case "row-wrap":
      return (
        <RowWrap responsiveReorder gap={gap} {...rest}>
          {children}
        </RowWrap>
      );
    default:
      return (
        <Row responsiveReorder gap={gap} {...rest}>
          {children}
        </Row>
      );
  }
};

export default SelectorDisplays;
