import { Button, Table } from "antd";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Heading, Row } from "util-styled-components";
import { getTipoSaludLabel } from "utils/getConstants";
import { generateMapTable } from "utils/tables";

const ListarCentroSalud = () => {
  const { data } = useAxios("GET", "/Establecimientos-activos");
  const navigate = useNavigate();

  const active = () => {
    navigate("/administrador/centros-de-salud/activar");
  };
  const crear = () => {
    navigate("/administrador/centros-de-salud/crear");
  };
  return (
    <Container>
      <Row justifyContent="space-between">
        <Heading variant="h2">Lista de Centros de Salud</Heading>
        <Row gap="10px">
          <Button type="dashed" onClick={active}>
            Activar CS
          </Button>
          <Button type="primary" onClick={crear}>
            Crear CS
          </Button>
        </Row>
      </Row>
      <Table
        dataSource={data}
        columns={generateMapTable([
          {
            dataIndex: "IdRegistro",
            title: "Registro",
          },
          "Nombre",
          {
            dataIndex: "IdTipoEstablecimiento",
            title: "Tipo de establecimiento",
            render: (_, row) => getTipoSaludLabel(row.IdTipoEstablecimiento),
          },
        ])}
      />
    </Container>
  );
};

export default ListarCentroSalud;
