import { Button, Card, Input, Menu, message, Modal, Switch, Table } from "antd";
import { Container } from "components/skeleton/Container";
import { format } from "date-fns";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, Grid, Heading, Row, Typography } from "util-styled-components";
import { generateMapTable } from "utils/tables";
import {
  faFile,
  faFileEdit,
  faFilter,
  faInfo,
  faInfoCircle,
  faPlus,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformacionResidente from "components/molecules/InformacionResidente";

const residentesURI = "/administrador/residentes";

const ListarResidentes = () => {
  const { data, sendRequest } = useAxios("GET", "/Lista-residentes");
  const [listaFiltros, setListaFiltros] = useState({ usuariosActivos: true });
  const CambiarEstadoActivo = useAxios(
    "POST",
    "/usuario-web/cambiarEstado",
    {},
    false
  );
  const nav = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);

  const [filtro, setFiltro] = useState("");

  const navigate = (url) => {
    nav(`${residentesURI}${url}`);
  };

  useEffect(() => {
    if (CambiarEstadoActivo.responseCode === 200) {
      message.info("Se ha cambiado el estado del usuario");
      sendRequest();
    }
    if (CambiarEstadoActivo.responseCode === 500) {
      message.danger(
        "Se ha producido un error al cambiar el estado del usuario"
      );
    }

    return () => {};
  }, [CambiarEstadoActivo.responseCode]);

  const cambiarEstadoUsuario = (idUsuario, estadoNuevo) => {
    CambiarEstadoActivo.updateParams({
      IdUsuario: idUsuario,
      EstadoNuevo: estadoNuevo,
    });
    CambiarEstadoActivo.initRequest();
  };

  const filterNombre = (el) =>
    filtro
      ? el.NombresCompletos.toUpperCase().includes(filtro.toUpperCase())
      : el;

  const filtroUsuarioActivo = (el) => {
    if (listaFiltros.usuariosActivos) {
      return el.EstadoUsuario === 1;
    }
    return true;
  };

  return (
    <Container>
      <Modal
        title="Filtrar"
        visible={openFilter}
        onCancel={() => setOpenFilter(false)}
        footer={null}
      >
        <Row justifyContent="space-between">
          <Typography>Ver solo residentes con usuarios activos</Typography>
          <Switch
            checked={listaFiltros.usuariosActivos}
            onChange={(activeValue) =>
              setListaFiltros({ ...listaFiltros, usuariosActivos: activeValue })
            }
          />
        </Row>
      </Modal>
      <Row justifyContent="space-between" responsiveReorder>
        <Heading variant="h2">Lista de residentes</Heading>

        <Row gap="5px">
          <Input.Search
            allowClear
            placeholder="Buscar..."
            value={filtro}
            onChange={({ target }) => setFiltro(target.value)}
            style={{ width: 200 }}
          />
          <Button
            type="primary"
            onClick={() => nav("/administrador/residentes/crear")}
          >
            <Row gap="5px">
              <FontAwesomeIcon icon={faPlus} />
              <strong>Nuevo Residente</strong>
            </Row>
          </Button>
          <Button type="link" onClick={() => setOpenFilter(true)}>
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </Row>
      </Row>
      <Table
        dataSource={
          data
            ? data
                .map((el, key) => ({ key, ...el }))
                .filter(filtroUsuarioActivo)
                .filter(filterNombre)
            : []
        }
        columns={generateMapTable([
          {
            className: "no-padding",
            render: (_, row) => (
              <InformacionResidente
                margin="0"
                borderStyle="none"
                data={row}
                inactiveButtonMoreInfo
                extra={
                  <Column responsiveReorder>
                    <Button
                      type="dashed"
                      onClick={() => navigate(`/rotaciones/${row.IdResidente}`)}
                    >
                      <Row gap="5px">
                        <FontAwesomeIcon icon={faFile} /> Ver Rotaciones
                      </Row>
                    </Button>
                    <Button
                      type="link"
                      onClick={() => {
                        nav(`${residentesURI}/editar/${row.IdResidente}`, {
                          state: row,
                        });
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        cambiarEstadoUsuario(
                          row.idusuarioweb,
                          parseInt(row.EstadoUsuario === 1 ? 0 : 1)
                        );
                      }}
                    >
                      {row.EstadoUsuario === 1
                        ? "Desactivar Usuario"
                        : "Activar Usuario"}
                    </Button>
                  </Column>
                }
              />
            ),
          },
        ])}
      />
    </Container>
  );
};

export default ListarResidentes;

/**

"DNI",
          "CMP",
          {
            dataIndex: "FechaIngreso",
            render: (_, row) =>
              format(new Date(row.FechaIngreso), "dd/MM/yyyy"),
          },
          "Nombres",
          "Apellidos",
          "NombreEspecialidad",

 */
