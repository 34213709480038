import { message } from "antd";
import { Container } from "components/skeleton/Container";
import useAxios from "hooks/useAxios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formulario } from "ts_formulario_model";
import { Div } from "util-styled-components";
import momentTZ from "utils/momentTZ";
import { MapFormulario } from "./MapFormulario";
const CrearResidente = () => {
  const Especialidades = useAxios("GET", "/Listar-Especialidades");
  const APICrearResidente = useAxios("POST", "/Crear-residente", {}, false);
  const nav = useNavigate();

  useEffect(() => {
    if (APICrearResidente.responseCode) {
      APICrearResidente.responseCode === 200 &&
        message.success("Se cargó el residente");
      APICrearResidente.responseCode === 400 &&
        message.error("No se cargó el residente");
      nav("/administrador/residentes");
    }

    return () => {};
  }, [APICrearResidente.responseCode]);

  const guardarResidente = (data) => {
    try {
      const params = {
        ...data,
        IdEspecialidad: data.Especialidad[0].IdEspecialidad,
        Condicion: data.CondicionResidente[0].id,
        FechaIngreso: momentTZ.getDateT00Z(data.FechaIngreso, "YYYY-MM-DD"),
        Domicilio: data["Dirección"],
      };
      APICrearResidente.updateParams(params);
      APICrearResidente.initRequest();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div width="92%" margin="auto">
      <Formulario onSubmit={guardarResidente}>
        <Formulario.Title>Registrar un residente</Formulario.Title>
        <Formulario.FormControls
          onCancel={() => {
            nav("/administrador/residentes");
          }}
          inputs={[
            {
              name: "HospitalProcedencia",
              type: "input",
              required: true,
              customProps: {
                placeholder: "Ingrese el nombre del hospital",
                required: true,
              },
            },
            {
              name: "Especialidad",
              type: "dropdown",
              required: true,
              optionsDropdown: Especialidades.data,
              optionValue: "NombreEspecialidad",
              optionLabel: "NombreEspecialidad",
              customProps: {
                required: true,
              },
            },
            ...MapFormulario,
          ]}
        />
      </Formulario>
    </Div>
  );
};

export default CrearResidente;
