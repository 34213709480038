// import { useUsuario } from "hooks/ContextUser";
import { FormLogin, FormLoginFooter, Portada } from "./styled";
import Logo from "components/atoms/Logo";
import { Button } from "components/atoms/Button";
import { Controller, useForm } from "react-hook-form";
import { Input, message } from "antd";
import { ReactComponent as PortadaSVG } from "assets/img/Portada.svg";
import { Row, Column, Typography, Heading, Div } from "util-styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faUserAlt,
  faKey,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useUsuario } from "hooks/ContextUser";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const { control, handleSubmit } = useForm();
  const [showPass, setShowPass] = useState(false);
  const { login } = useUsuario();
  const nav = useNavigate();
  const onSubmit = async ({ username, password }) => {
    const response = await login(username, password);

    if (response[2] === 200) {
      message.success(response[1]);
      nav("/");
    } else {
      message.error(response[1]);
    }
  };
  return (
    <Div width="90%" margin="0 auto">
      <Column justifyContent="space-around" height="100vh">
        <Logo />
        <Row responsiveReorder>
          <Portada>
            <PortadaSVG />
          </Portada>
          <FormLogin onSubmit={handleSubmit(onSubmit)}>
            <Column gap="1em">
              <Heading variant="h4" center>
                Inicio de sesión en Residentado - UADI - HAPCSRII2
              </Heading>
              <Column>
                <Typography for="username">Username</Typography>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input
                      size="large"
                      prefix={
                        <FontAwesomeIcon
                          className="icon-input"
                          icon={faUserAlt}
                        />
                      }
                      placeholder="Ingrese su usuario"
                      id="username"
                      {...field}
                    />
                  )}
                />
              </Column>
              <Column>
                <Typography for="password">Contraseña</Typography>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="password"
                      type={showPass ? "text" : "password"}
                      size="large"
                      prefix={
                        <FontAwesomeIcon className="icon-input" icon={faKey} />
                      }
                      suffix={
                        <FontAwesomeIcon
                          color="#c3c3c3"
                          onClick={() => setShowPass(!showPass)}
                          icon={showPass ? faEyeSlash : faEye}
                        />
                      }
                      placeholder="Contraseña"
                      {...field}
                    />
                  )}
                />
              </Column>
              <Button fullSize primary>
                INICIAR SESIÓN
              </Button>
            </Column>
          </FormLogin>
        </Row>
        <FormLoginFooter>UADI-Residentado &copy; 2022</FormLoginFooter>
      </Column>
    </Div>
  );
};

export default Login;
