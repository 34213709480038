import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { Theming } from "theme/Theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.min.css";
import { HashRouter } from "react-router-dom";
import { UsuarioProvider } from "hooks/ContextUser";
import { LoadingProvider } from "hooks/LoadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <LoadingProvider>
      <UsuarioProvider>
        <ThemeProvider theme={Theming}>
          <App />
        </ThemeProvider>
      </UsuarioProvider>
    </LoadingProvider>
  </HashRouter>
);

reportWebVitals();
