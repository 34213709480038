import moment from 'moment'
import 'moment-timezone'
const momentTZ = {}
momentTZ.getDateT00Z = (fecha,format='DD/MM/YYYY') => {
    return moment(fecha).tz('Africa/Nouakchott').format(format)
}    
momentTZ.getDateNoTZ = (fecha,format='DD/MM/YYYY') => {
    return moment(fecha).format(format)
}    
momentTZ.añoActual = () => {
    const fecha = new Date()
    return fecha.getFullYear()
}
export default momentTZ