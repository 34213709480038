import { faBars, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import Logo from "components/atoms/Logo";
import { useUsuario } from "hooks/ContextUser";
import { Row, Typography } from "util-styled-components";
import { UserName } from "./styled";
const NavBar = ({ setVisible }) => {
  const { usuario } = useUsuario();
  return (
    <Row
      justifyContent="space-between"
      padding="20px 3em"
      responsiveReorder
      gap="10px"
    >
      <Logo />
      <Row gap="1em" alignSelf="flex-end">
        <UserName gap=".5em" onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faUserAlt} />
          {usuario && <Typography>{usuario.user.username}</Typography>}
        </UserName>
        <Button type="primary" shape="circle" onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
      </Row>
    </Row>
  );
};

export default NavBar;
