import { Card } from "antd";
import InformacionResidente from "components/molecules/InformacionResidente";
import { Container } from "components/skeleton/Container";
import { useUsuario } from "hooks/ContextUser";
import useAxios from "hooks/useAxios";
import { useDataActualizadaResidente } from "hooks/useDataActualizadaResidente";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "util-styled-components";

const Home2 = () => {
  const { usuario } = useUsuario();
  const { data, actualizar } = useDataActualizadaResidente(
    usuario.user.IdResidente
  );
  const nav = useNavigate();

  return (
    <Container>
      <span>Bienvenido!</span>
      <InformacionResidente data={data} />
      <Row gap=".5em">
        <Card
          hoverable
          style={{ width: "100%" }}
          onClick={() =>
            nav(`/residente/mis-rotaciones/${usuario.user.IdResidente}`)
          }
        >
          Ver mis rotaciones
        </Card>
        <Card
          hoverable
          style={{ width: "100%" }}
          onClick={() => nav(`/residente/${usuario.user.IdResidente}`)}
        >
          Ver mi información
        </Card>
      </Row>
    </Container>
  );
};

export default Home2;
