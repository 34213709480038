export const TipoCentroSalud = [
  { name: "MINSA", value: "M" },
  { name: "ESSALUD", value: "ES" },
  { name: "Particular Nacional", value: "PN" },
  { name: "Particular Internacional", value: "PI" },
  { name: "Estatal Internacional", value: "EI" },
];

export const Anios = [
  {
    name: "Primero",
    value: "Primero",
  },
  {
    name: "Segundo",
    value: "Segundo",
  },
  {
    name: "Tercero",
    value: "Tercero",
  },
];
