import React, { useState, useEffect, useMemo } from 'react'
import Axios from 'axios'
import useLocalStorage from './useLocalStorage'
import { useLoading } from './LoadingContext'
import { params, url } from './useAxios'
import { useNavigate } from 'react-router-dom'

const UsuarioContext = React.createContext()
export function UsuarioProvider (props) {
  const [initVal, setInitVal] = useState(true)
  const [userIsLogged, setUserIsLogged] = useState(false)
  const [usuario, setUsuario] = useState(null)
  const { changeLoading } = useLoading()
  const [setLogin, getLogin] = useLocalStorage('ResidentadoUserLogin')
  const [tokenInterceptor, setTokenInterceptor] = useState('')
  useEffect(() => {
    recuperarUsuario()
    setInitVal(false)
    return () => {}
    //eslint-disable-next-line
  }, [])

  const recuperarUsuario = async e => {
    changeLoading()
    const store = getLogin()
    if (store && store.login) {
      setUserIsLogged(true)
      setUsuario(store)
      setTokenInterceptor(store.store + ' ' + store.user.idrol)
    } else {
      setTokenInterceptor('')
      setUserIsLogged(false)
      setUsuario(null)
    }
    changeLoading(false)
  }
  const login = async (username, password) => {
    changeLoading()
    try {
      const res = await Axios.post(`${url}${params}`, {
        method: 'POST',
        route: '/api/uadi/residentado/login/2',
        params: {
          username: username,
          password: password
        }
      })
      const { user, token } = res.data
      if (user) {
        setLogin({
          login: true,
          store: token,
          user
        })
      } else {
        changeLoading(false)
        return [
          'AUTENTICACION INCORRECTA',
          'USUARIO NO ENCONTRADO O DESACTIVADO',
          501
        ]
      }
      recuperarUsuario()
      changeLoading(false)
      return ['AUTENTICACION CORRECTA', '¡Bienvenido!', 200]
    } catch (error) {
      changeLoading(false)
      return [
        'AUTENTICACION INCORRECTA',
        'VERIFIQUE SU USUARIO Y CONTRASEÑA',
        501
      ]
    }
  }
  const logout = e => {
    localStorage.removeItem('ResidentadoUserLogin')
    setUserIsLogged(false)
    sessionStorage.clear()
    setUsuario(null)
  }

  const value = useMemo(() => {
    return {
      usuario,
      login,
      logout,
      initVal,
      userIsLogged,
      tokenInterceptor
    }
    //eslint-disable-next-line
  }, [usuario, initVal, userIsLogged])
  return <UsuarioContext.Provider value={value} {...props} />
}
export function useUsuario () {
  const context = React.useContext(UsuarioContext)
  if (!context) {
    throw new Error('No se encontro Usuario en el contexto')
  }
  return context
}
