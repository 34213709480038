import {
  CrearResidente,
  Home,
  ListarEspecialidades,
  ListarResidentes,
  ActivarEspecialidad,
  ListarCentroSalud,
} from "pages";
import ActivarCentroSalud from "pages/CentroSalud/Activar";
import CrearCentroSalud from "pages/CentroSalud/Crear";
import RotarResidente from "pages/Residentes/Rotaciones/Rotar";
import SubirNotasResidentes from "pages/Residentes/Notas/SubirNotas";
import VerNotasResidentes from "pages/Residentes/Notas/VerNotas";
import VerRotacionesResidentes from "pages/Residentes/Rotaciones/VerRotaciones";
import { Route, Routes } from "react-router-dom";
import MiPerfil from "pages/Residente/MiPerfil";
import MisRotaciones from "pages/Residente/MisRotaciones";
import { useUsuario } from "hooks/ContextUser";
import Home2 from "pages/Home/Home2";
import EditarRotacion from "pages/Residentes/Rotaciones/EditarRotacion";
import EditarResidente from "pages/Residentes/Editar";
import ReporteRotaciones from "pages/Reportes/ReporteRotaciones";
import ReporteEvaluacionesExternas from "pages/Reportes/ReporteRotacionesExternas";
import ReporteEvaluacionesExternasEspecialidad from "pages/Reportes/ReporteRotacionesExternasEspecialidad";
import RotacionesXMes from "pages/Reportes/RotacionesXMes";

const RoutesX = () => {
  const { usuario } = useUsuario();
  return (
    <Routes>
      <Route path="/">
        {usuario && usuario.user && (
          <Route
            index
            element={usuario.user.idrol === 3 ? <Home2 /> : <Home />}
          />
        )}
        {usuario.user.idrol && (
          <Route path="residente">
            <Route path=":id" element={<MiPerfil />} />
            <Route path="mis-rotaciones">
              <Route path=":id" element={<MisRotaciones />} />
            </Route>
          </Route>
        )}
        {usuario.user.idrol !== 3 && (
          <Route path="administrador">
            <Route path="residentes">
              <Route index element={<ListarResidentes />} />
              <Route path="crear" element={<CrearResidente />} />
              <Route path="editar/:id" element={<EditarResidente />} />
              <Route path="residente">
                <Route path=":id" element={<MiPerfil />} />
              </Route>
              <Route path="notas/:id">
                <Route index element={<VerNotasResidentes />} />
                <Route path="crear">
                  <Route
                    path=":idRotacion"
                    element={<SubirNotasResidentes />}
                  />
                </Route>
              </Route>
              <Route path="rotaciones/:id">
                <Route index element={<VerRotacionesResidentes />} />
                <Route path="crear" element={<RotarResidente />} />
                <Route path="editar/:idRotacion" element={<EditarRotacion />} />
              </Route>
            </Route>
            <Route path="especialidades">
              <Route index element={<ListarEspecialidades />} />
              <Route path="activar" element={<ActivarEspecialidad />} />
            </Route>
            <Route path="centros-de-salud">
              <Route index element={<ListarCentroSalud />} />
              <Route path="activar" element={<ActivarCentroSalud />} />
              <Route path="crear" element={<CrearCentroSalud />} />
            </Route>
          </Route>
        )}
        {usuario.user.idrol !== 3 && (
          <Route path="reportes">
            <Route path="rotaciones" element={<ReporteRotaciones />} />
            <Route
              path="rotacionesExternas"
              element={<ReporteEvaluacionesExternas />}
            />
            <Route
              path="rotacionesExternasEspecialidad"
              element={<ReporteEvaluacionesExternasEspecialidad />}
            />
            <Route path="rotacionesXMes" element={<RotacionesXMes />} />
          </Route>
        )}
      </Route>
    </Routes>
  );
};

export default RoutesX;
