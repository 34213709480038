import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;
const props = {
  name: "FilePDF",
  multiple: false,
  action: "https://media.hsantarosa.gob.pe/uploadPDF.php",
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const comprobarFuncion = (cb) => {
  return typeof cb === "function";
};

const DragDrop = ({ uploadedFile, uploadedFiles, maxFiles = 1 }) => (
  <Dragger
    {...props}
    onChange={(info) => {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file);
        comprobarFuncion(uploadedFile) && uploadedFile(info.file);
        comprobarFuncion(uploadedFiles) && uploadedFiles(info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} se ha cargado con exito.`);
      } else if (status === "error") {
        message.error(`${info.file.name} ha generado un error al cargar.`);
      }
    }}
    maxCount={maxFiles}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">
      Click o arrastar su archivo en el area sombrada para cargar su archivo
    </p>
    <p className="ant-upload-hint">Soporta maximo un archivo de 16MB</p>
  </Dragger>
);

export default DragDrop;
